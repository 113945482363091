import React from "react";
import { graphql } from "gatsby";
import PageWithCmsWrapper from "../components/shared/page-with-cms-wrapper";

const Style = ({
                   path,
                   data: {
                       strapiStyle: {
                         Title,
                         Content,
                         SeoTitle,
                         MetaDescriptions,
                       },
                   },
               }) => {
    return (
        <PageWithCmsWrapper
            path={path}
            title={Title}
            content={Content}
            seoProps={{ title: SeoTitle, description: MetaDescriptions}}
        />
    );
};


export default Style;

export const query = graphql`
  query StyleQuery {
    strapiStyle {
      Title
      Content
      SeoTitle
      MetaDescriptions
    }
  }
`;
